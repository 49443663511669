.map {
    width: 100%;
    height: 400px;
}

#tooltip-openlayer {
    position: absolute;
    opacity: 0;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    z-index: 15;
    max-width: 10rem;
    // height: 8rem;

    p {
        color: rgb(12, 105, 97);
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0;
    }

    img {
        width: 100%;
    }
}

#overlay-popup {
    max-height: 400px;
    overflow-y: auto;
    position: relative;
    z-index: 99;
    top: 20px;
    left: -20px;
    border-radius: 5px;
}

#overlay_close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 11;
}

#overlay-content {
    width: 300px;
    background: #f1f1f1;
    // border-radius: 5px;
    padding: 10px;
    position: relative;
    top: 0;
    left: 0;

    &::before {
        content: "";
        position: fixed;
        z-index: 100;
        top: 0;
        left: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #f1f1f1;
    }

    .overlay_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px 5px;

        p {
            font-size: 17px;
            font-weight: 600;
            color: #4b4b4b;
            margin-bottom: 0;
        }

        #overlay_close {
            cursor: pointer;
            padding: 0 5px;
            font-size: 18px;
            font-weight: 800;
        }
    }

    img {
        border: 1px solid #cdcdcd;
        width: 100%;
    }

    p {
        color: #fff;
        text-align: center;
    }

    table {
        width: 100%;

        th,
        td {
            padding: 5px;
            border: 1px solid #cdcdcd;
        }
    }
}

#overlay-content1 {
    display: none;
    width: 300px;
    background: #f1f1f1;
    // border-radius: 5px;
    padding: 10px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #f1f1f1;
    }

    .title {
        p {
            font-size: 17px;
            font-weight: 600;
            color: #4b4b4b;
        }
    }
    .content_item {

    }

    #handle-now {
        margin-top: 10px;
        cursor: pointer;
        color: #0c6961;
        text-align: center;
    }
}

.map:-webkit-full-screen {
    height: 100%;
    margin: 0;
}

.map:fullscreen {
    height: 100%;
}

.map .ol-rotate {
    top: 3em;
}

.ol-full-screen-false {
    display: none !important;
}

.ol-zoom {
    display: none;
}

.ol-attribution {
    display: none;
}