.header_container {
    background: #fff;
    box-shadow: 0 0 4px 2px #cdcdcd;
    position: relative;

    .navbar {
        .navbar-nav {
            gap: 10px;

            .nav-item {
                a {
                    color: rgb(17, 153, 142);
                }
            }

            &.f_c_u {
                flex-direction: row;
                justify-content: center;
            }

            &.nav_menu {
                gap: 15px;
                margin-right: 70px;
                align-items: center;

                .nav-item {
                    .nav_item {
                        background-color: rgb(17, 153, 142);
                        box-shadow: 0 0 2px 1px #cdcdcd;
                        padding: 5px 10px;
                        border-radius: 3px;
                        color: #fff;

                        // text-transform: uppercase;
                        &:hover,
                        &.active {
                            box-shadow: 0 0 4px 2px #cdcdcd;
                            background-color: #f1f1f1;
                            color: rgb(17, 153, 142);
                            // a {
                            //     background-color: #f1f1f1;
                            //     color: rgb(17, 153, 142);
                            // }
                        }

                        &.active {
                            a {
                                cursor: default;
                            }
                        }

                        &:hover {
                            text-decoration: none;
                        }

                        @media (max-width: 992px) {
                            display: flex;
                            min-width: 300px;
                            justify-content: center;
                            align-items: center;
                            background-color: rgb(17, 153, 142);

                            a {
                                box-shadow: unset;
                                border: unset;

                                &:hover,
                                &.active {
                                    box-shadow: unset;

                                    a {
                                        background-color: #f1f1f1;
                                        color: rgb(17, 153, 142);
                                    }
                                }
                            }

                            &:hover,
                            &.active {
                                background-color: #f1f1f1;
                            }
                        }
                    }




                }

                @media (max-width: 1100px) {
                    gap: 10px;
                    margin-right: 0px;
                }
            }
        }

        .navbar-brand {
            display: flex;
            gap: 10px;

            .brand_name {
                h3 {
                    font-weight: 900;
                    line-height: 38px;
                    letter-spacing: 5px;
                    margin: 0;
                    color: rgb(15, 108, 101);
                }

                p {
                    font-size: 12px;
                    line-height: 0px;
                    margin: 0;
                    color: rgb(17, 153, 142);
                }
            }
        }

        @media (max-width: 1150px) {
            padding: 0.5rem 0;
        }
    }

    .navbar-collapse {
        justify-content: end;
    }
}