.history_rs {
    position: relative;
    // background-color: #fff;
    // box-shadow: 0 0 8px #cdcdcd;
    border-radius: 3px;
    min-height: calc(100vh - 230px);
    // min-height: 600px;
    margin-bottom: 16px;

    // padding: 0 !important;
    .MuiPaper-root {
        max-height: calc(100vh - 360px);
    }

    .gap_20 {
        gap: 20px;
    }

    .form-item {
        margin-top: 1rem;
    }

    .list_block,
    .list_detail_block {
        background: #fff;
        box-shadow: 0 0 8px #cdcdcd;
        border-radius: 3px;
        height: calc(100vh - 340px);
        // min-height: 600px;
        margin-bottom: 16px;
    }

    .list_block {
        position: relative;



        .search_result {
            height: calc(100% - 90px);
            overflow-y: auto;
            overflow-x: hidden;
            padding: 16px;
            margin-bottom: 50px;
            margin-top: 10px;

            .name_result {
                position: relative;
                text-align: center;
                margin-bottom: 1rem;

                p {
                    font-weight: 500;
                    margin: 0;
                }

                h4 {
                    font-size: 20px;
                    line-height: 22px;
                    color: #11998e;
                }

                .refresh_btn {
                    position: absolute;
                    top: -22px;
                    left: 0;
                }
            }

            .title_ {
                font-size: 22px;
                text-transform: uppercase;
            }

            .list_rs {
                .rs_item {
                    padding: 5px;
                    margin-top: 2px;
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    border-radius: 3px;

                    .rs_date {
                        font-size: 14px;
                    }

                    .rs_address {
                        color: #11998e;
                    }

                    .coord {
                        color: #cdcdcd;
                    }

                    &:hover,
                    &.active {
                        background: #11998e;

                        .rs_date,
                        .rs_address,
                        .coord {
                            color: #fff;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &.active {
                        cursor: default;
                    }
                }
            }
        }
    }

    .list_detail_block {
        padding: 16px;
        position: relative;
        max-height: 100%;

        .name_address {
            .name_address_header {
                display: flex;
                align-items: center;
                gap: 20px;

                .name {
                    font-size: 24px;
                    color: #11998e;
                    font-weight: 600;
                }

                .coord {
                    color: #cdcdcd;
                }
            }

            .note_update {
                font-size: 14px;
            }
        }

        .card {
            background-color: #f1f1f1;
            width: calc(100% - 90px);
            min-height: 180px;
            border-radius: 5px 0 0 5px;
            overflow-x: auto;
        }

        .add_img {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 90px;
            background: #f1f1f1;
            padding: 19px 16px 19px 12px;

            .add_img_btn {
                cursor: pointer;
                width: 100%;
                height: 142px;
                border: 1px solid #cdcdcd;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                font-weight: 900;

                &:hover {
                    background: #cdcdcd;
                    color: #fff;
                }
            }
        }

        .list_img {
            flex-wrap: nowrap;
        }
    }

    .img_avatar {
        width: 120px;
        height: 90px;
        object-fit: cover;
    }

    .add_history {
        padding: 20px;
    }
}

.search_block {
    background-color: #fff;
    padding: 0 16px 1px;
    margin-bottom: 1rem;
    box-shadow: 0 0 8px #cdcdcd;
    border-radius: 5px;

    .search_input_select {
        max-width: 300px;
    }

    .filter_item {

        select {
            position: relative;
            bottom: -5px;
            padding: 5px 10px;
            border: unset;
            border-bottom: 1px solid #000;
        }
    }
}

.form_search_rs {
    .form-select {
        width: 100%;
        padding: 7px 10px;
        border: 1px solid #cdcdcd;
        border-radius: 4px;
    }

}
.form-select {
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
}