.table_header {
    width: 100%;
    padding: 10px 15px;
    background: #11998e;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    .table_header_right {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    .table_header_title {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
    }

    .count {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
    }
    .MuiInputLabel-formControl {
        transform: unset;
    }
}

.pagination_footer {
    display: flex;
    align-items: center;
    gap: 10px;
    float: inline-end;

    .select-page {
        display: flex;
        align-items: center;
    }
}

.table_header_filter_commune {
    min-width: 200px;
    background-color: #fff;
    padding: 5px 10px 2px 10px;
    border-radius: 3px;
    border: 1px solid #b4b4b4;

    .MuiInput-underline {

        &::before,
        &::after {
            display: none;
        }
    }
}

.table_header_filter_date {
    display: flex;
    gap: 10px;

    .filter_date {
        .MuiInputBase-input {
            margin-top: 10px;
        }

        width: 200px;
        background-color: #fff;
        padding: 3px 10px 0 10px;
        border-radius: 3px;

        .MuiInput-underline {

            &::before,
            &::after {
                display: none;
            }
        }
    }
}

.table_header_filter_status {
    select {
        padding: 5px 10px;
        border: 1px solid #fff;
        border-radius: 3px;
    }
}

.table_header_filter_time {
    min-width: 150px;
}

.table_header_filter_time {
    background-color: #fff;
    border-radius: 4px;
}

.flex-gap-10 {
    gap: 10px;
}