.show-notifications {
    transition-duration: .5s;
    width: 300px;
    height: 110px;
    padding: 10px;
    position: fixed;
    z-index: 9999;
    bottom: 50px;
    left: -300px;
    background: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 0 5px 1px #cdcdcd;
    color: #4b4b4b;

    &.active,
    &:hover {
        left: 50px;
    }

    .title {
        margin-bottom: 10px;
        font-weight: 600;
        color: #0c6961;
    }
    .content {
        cursor: pointer;
    }
}