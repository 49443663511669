.pending_map_view {
    #mouse-position {
        display: block;
    }
    .map_controller {
        position: absolute;
        top: -20px;
        transition-duration: .3s;
        left: 330px;
        // bottom: 0;
        width: calc(100vw - 330px);
        background: #cdcdcde3;        
    }
}