.footer_container {
    background: #fff;
    box-shadow: 0 0 2px 1px #cdcdcd;

    .footer_content {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        padding: 10px;

        .footer_right {
            display: flex;
            gap: 50px;
        }

        p {
            margin: 0;
        }
    }

}

.mw_1500 {
    max-width: 1520px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;

    .MuiTableBody-root {
        .MuiTableRow-root {
            .MuiTableCell-root {
                font-size: 14px !important;

                &.MuiTableCell-freeze {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                    background-color: #ffffff;
                    max-width: 300px;
                    min-width: 300px;

                    @media (max-width: 767px) {
                        max-width: 200px !important;
                        min-width: 200px !important;
                    }
                }

                @media (max-width: 767px) {
                    &.MuiTableCell-body {
                        min-width: 200px !important;
                    }
                }

                &.MuiTableCell-body {
                    min-width: 150px !important;
                }
            }
        }
    }
}

.mw_1200 {
    max-width: 1200px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}