.sidebar {
    background-color: #00923F;
    background-image: none;

    &.toggled {
        #sidebarToggle {
            &::after {
                content: none;
            }
        }
    }

    #sidebarToggle {
        &::after {
            content: none;
        }
    }

    .nav-item {
        .nav-link[data-toggle="collapse"] {
            &::after {
                content: none !important;
            }
        }
    }
}

button {
    &:focus {
        outline: none;
    }
}

:focus {
    outline: none;
}

.error {
    font-size: .8rem;
    margin-top: 5px;
    color: red;
}

.required {
    display: inline-block;

    &::before {
        content: "*";
        color: #dc3545;
        margin-left: 5px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-move {
    cursor: move;
}

.drag-state-custom-ddb {
    background-color: rgba(10, 206, 10, 0.623);
}

.warning-validate-style-custom {
    color: red;
    font-size: 12px;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.alignItems-baseline {
    align-items: baseline;
}

.map-link {
    color: #4e73df;
    cursor: pointer;

    &:hover {
        color: #0a32ac;
    }
}

.w-10 {
    width: 10% !important;
}

.text-info {
    color: #17a2b8 !important;
}

.text-success {
    color: #28a745 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;

    &:hover {
        color: #fff;
        background-color: #218838;
        border-color: #1e7e34;
    }
}

.fw-5 {
    font-weight: 500 !important;
}

.fw-6 {
    font-weight: 600 !important;
}

.border-1 {
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    padding: 15px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-20 {
    padding-top: 20px;
}

//--- Material table
.MuiTable-root {
    .MuiTableHead-root {
        .MuiTableRow-root {
            .MuiTableCell-root {
                background-color: #ffffff;

                &.MuiTableCell-freeze {
                    z-index: 3;
                    box-shadow: 0 0 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
                }
            }
        }
    }

    .MuiTableBody-root {
        .MuiTableRow-root {
            .MuiTableCell-root {
                font-size: 14px !important;

                &.MuiTableCell-freeze {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                    background-color: #ffffff;
                    max-width: 300px;
                    min-width: 300px;
                }
            }
        }
    }
}

//--- Scrollbar
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eeeeee;
    // border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    // border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #6d6e70;
}

.MuiPopover-root {
    background-color: rgba(0, 0, 0, 0.5);
}