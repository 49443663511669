.notifications {
    position: relative;

    .dropdown-item {
        white-space: unset;
    }

    .noti_ref {
        position: absolute;
    }

    .noti_menu {
        position: absolute !important;
        left: -125px;
        z-index: 111;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 5px #f1f1f1;
        padding-top: 10px;
        max-height: 80vh;
        width: 350px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: 1700px) {
            left: -225px;
        }

        @media (max-width: 992px) {
            left: -125px;
        }

        // white-space: nowrap !important;
        &::-webkit-scrollbar {
            display: none;

        }

        &::-webkit-scrollbar-track {
            box-shadow: unset;
            border-radius: 10px;
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }

        &:hover {
            &::-webkit-scrollbar {
                display: block;

            }
        }

        p {
            margin-bottom: 0;
        }

        .dropdown_item {
            width: 310px;
            position: relative;
            cursor: pointer;
            padding: 10px 20px;
            margin: 10px 20px;
            // border-bottom: 1px solid #cdcdcd;
            border-radius: 10px;
            background-color: #fdfdfd;
            white-space: unset;
            color: #3a3b45;
            text-align: inherit;
            clear: both;

            &.unread {
                background-color: #f5f5f5;

                &:hover {
                    background-color: #fdfdfd;
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #385898;
                }
            }

            .noti_content {
                margin-bottom: 5px;
            }

            &:hover {
                .noti_options {
                    display: block;
                }
            }

            .noti_options {
                display: none;
                position: absolute;
                right: 30px;
                top: 38%;
                z-index: 100;
                border-radius: 50%;
                // border: 1px solid #d8d8d8;
                padding: 3px;
                background: #fff;

                button {
                    &::after {
                        display: none;
                        background-color: #fff;
                    }
                    background-color: #fff !important;
                    border-radius: 50% !important;
                    border: 1px solid #e8e8e8 !important;
                    padding: 5px 7px !important;
                    svg {
                        color: #4b4b4b !important;
                    }
                    &:hover {
                        background-color: #e8e8e8 !important;
                    }
                }

                &:hover {
                    // background: #d8d8d8;
                    display: block;
                }

                .option_icon {
                    border: 1px solid #d8d8d8;
                    border-radius: 50%;
                    background: #fff;

                    &:hover {
                        background: #d8d8d8;
                        display: block;
                    }

                }

            }
        }
    }
}