.form_rs {
    .border-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.btn_rs {
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    box-shadow: 0 0 2px #cdcdcd;
    padding: 5px 20px;
    &:hover {
        box-shadow: 0 0 5px #cdcdcd;
    }
}
.MuiDropzoneArea-root {
    .MuiGrid-container {
        width: 100% !important;
        display: flex;    
    }
}
.MuiDropzonePreviewList-imageContainer {
    max-width: 290px !important;
}