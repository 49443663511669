.left-menu-layer-control-container {
    details {
        summary {
            background-color: black;
        }
    }

    .base-map-header-summary {

        .MuiAccordionSummary-expandIcon {
            position: absolute;
            left: 0px
        }

        .add-button-icon {
            right: 14px;
        }
    }

    .smooth-dnd-container.vertical {
        width: 100%;
        min-height: auto;
        min-width: auto;
    }

    .icon-layer {
        color: green;
    }

    .icon-layers-custom {
        left: 0px;
    }

    input[type=checkbox] {
        cursor: pointer;
    }

    .MuiAccordionSummary-root {
        &.Mui-expanded {
            min-height: 40px;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;

        &.Mui-expanded {
            margin: 10px 0 0 0;
        }
    }
}


.group-name {
    margin-bottom: 1rem !important;
}

.font-layer-title {
    font-size: 18px;
    font-weight: 600;
}

.font-layer-category {
    font-weight: 600;
    width: 3rem !important;
}

.validate-category {
    color: red !important;
    margin-left: 1rem !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    line-height: 1.66 !important;
}

@keyframes botToTop {
    from {
        left: -600px;
    }

    to {
        left: 0;
    }
}

.construction {
    height: 100%;
    
    .project__info {
        min-height: 60px;
        
        .header__info {
            margin: 0 2rem;
            background-color: #11998e;

            p {
                color: #fff;
                font-weight: 600;
                display: inline-block;
                margin: 0;
            }

            button {
                margin-top: 5px;
            }
        }

        .list__info {
            overflow-y: scroll;
            max-height: 713px;

            .detail__info {
                .avatar_ci {
                    img {
                        width: 100%;

                    }
                }

                .title_name {
                    p {
                        text-align: center;
                        font-size: 24px;
                        font-weight: 700;
                        padding: 5px;
                        margin: 0;
                    }

                }

                table {
                    width: 100%;

                    th,
                    td {
                        border: 1px solid #000;
                        padding: 5px;
                        width: auto;
                    }
                }

            }
        }

    }

    .construction_title {
        h2 {
            text-align: center;
            font-size: 1.4em;
            font-weight: 700;
            font-family: "Roboto";
            color: #0055ab;
            position: relative;
            margin-bottom: 0;
        }

    }


    .tab_ {
        background-color: aliceblue;
        min-height: 60px;

        .col-6 {
            border-bottom: 1px solid gray;
        }

        p {
            text-align: center;
            font-weight: 700;
            margin-top: 1rem;
        }

        .active {
            border-bottom: 3px solid #11998e;
        }
    }
}