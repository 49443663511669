.map_rs_container {
    position: relative;
    height: calc(100vh - 120px);

    .sidebar_rs {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 101;
        
        &.show_ {
            left: -350px;
        }
    }

    .map_controller {
        position: absolute;
        transition-duration: .3s;
        left: 330px;
        bottom: 0;
        width: calc(100vw - 330px);
        background: #cdcdcde3;

        &.full {
            width: 100vw;
            left: 0;
        }
    }

    #mouse-position {
        right: 50px;
        position: absolute;
        bottom: 10px;
        z-index: 2;
        color: #11998e;
        @media (max-width: 767px) {
            bottom: unset;
            right: 10px;
            top: 20px;
            background-color: #cdcdcdae;
            border-radius: 5px;
            padding: 2px;
        }
    }
}