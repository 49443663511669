.page_header {
    padding: 20px 0;
}

.export_ {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 125%;
}

.form-select {
    width: 100%;
}

.page_header2 {
    background: #fff;
    margin-bottom: 10px;
    padding: 0 10px 10px 10px;
    border-radius: 3px;
    box-shadow: 0 0 3px 1px #cdcdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.pending {
        padding: 10px;
    }
}

.page_filter {
    select {
        padding: 5px 10px;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
    }
}

.page_filter_ {
    width: 100%;

    .filter_item {
        margin-top: 10px;

        .MuiInputBase-input {
            margin-top: 10px;
        }

        select {
            position: relative;
            bottom: -5px;
            padding: 5px 10px;
            border: unset;
            border-bottom: 1px solid #000;
        }
    }
}

.filter_item {
    margin-top: 10px;

    .MuiInputLabel-formControl {
        transform: unset;
    }

    .MuiInputBase-input {
        margin-top: 10px;
    }

    select {
        position: relative;
        bottom: -5px;
        padding: 5px 10px;
        border: unset;
        border-bottom: 1px solid #000;
    }
}

.header_btton {
    display: flex;
    gap: 15px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.page_title {
    h3 {
        // color: #cdcdcd;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
        margin: 0;
    }
}

.cell-hl {
    background-color: #f1f1f1 !important;
}

hr.sidebar_ {
    margin: 0;
    border-color: gray;
}

.card-body {
    border: none;

    .list_img {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .img_item {
            position: relative;

            img {
                width: 230px;
                height: 140px;
                object-fit: cover;
                border-radius: 3px;
                box-shadow: 0 0 5px 1px #fff;
            }

            .img_item_overlay {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
                border-radius: 3px;

                svg {
                    cursor: pointer;
                    color: #ffffffe0;
                    display: none;

                    &:hover {
                        color: #ffffff;
                    }
                }
            }

            &:hover {
                img {
                    filter: brightness(70%);
                }

                .img_item_overlay {
                    svg {
                        display: block;
                    }
                }
            }
        }
    }
}

.export_option {
    display: flex;
    align-items: center;

    .title {
        cursor: pointer;
        background-color: #f1f1f1;
        padding: 7px 10px;
        border-radius: 3px 0 0 3px;
    }

    .btn_export_option {
        margin-left: 1px;
        background: #f1f1f1;
        border-radius: 0 3px 3px 0;
    }
    .dropdown-item {
        cursor: pointer;
    }
}

.gallery_preview {
    display: flex;
    gap: 10px;
    img {
        width: 150px;
        height: 110px;
        object-fit: cover;
    }
}