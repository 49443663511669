.container_list {
    padding-top: 10px;
    padding-bottom: 10px;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .length {
        display: flex;

        p {
            background-color: #11998e;
            border-radius: 15px;
            padding: 10px 15px;
            color: #fff;
            margin: 0;
        }

        span {
            color: red;
            font-size: 18px;
            font-weight: 700;
        }
    }

    .list_item {
        // height: 600px;
        overflow-y: auto;

        .project_item {
            position: relative;
            width: 50%;
            padding-right: 1rem;
            padding-bottom: 2rem;
            display: inline-block;

            .project_img {
                width: 100%;
                //max-height: 11rem;
                //width: 17.1875rem;
                height: 11rem;
                border-radius: 10px;
                box-shadow: 2px 2px 10px gray;
                background: rgb(89, 89, 89);
                background: linear-gradient(0deg, rgba(89, 89, 89, 1) 0%, rgba(46, 46, 46, 0) 100%);
            }

            .project_content {
                cursor: pointer;
                position: absolute;
                z-index: 1;
                top: 0;
                width: 95%;
                bottom: 22px;
                background: linear-gradient(0deg, #595959 0%, rgba(46, 46, 46, 0) 100%);
                color: #fff;
                border-radius: 10px;

                .project_title {
                    position: absolute;
                    width: 100%;
                    top: 4rem;
                    padding: 1rem;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-height: 64px;

                    p {
                        margin-bottom: 0;
                    }
                }

                .project_place {
                    position: absolute;
                    bottom: 0;
                    padding-left: 1rem;
                    font-size: 12px;
                }
            }
        }
    }

    .left-map-search {
        top: 70px;
        left: 0;
        position: absolute;
        z-index: 11;
        width: max-content;
        max-width: 17.5rem;
        box-shadow: 1px 1px 3px gray;

        .search_item {
            margin-left: 8px;
        }
    }



}

.pagination__ {
    display: flex;
    justify-content: end;
}

.sort__ {
    position: relative;
    z-index: 11;
}