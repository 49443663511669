.entry_screen{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;

    .entry_header {
        justify-content: center;
        margin-bottom: 50px;
        .entry_header_logo {
            img {
                width: 100px;
            }
        }
        .entry_header_title {
            font-weight: 700;
            text-transform: uppercase;
        }
        .nav-item {
            float: right;
            img {
                width: 50px;
            }
        }
    }
    .entry_box {
        justify-content: center;
        margin-bottom: 100px;
        .entry_item {
            width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 25px 15px;
            margin: 15px;
            border-radius: 5px;
            box-shadow: 0 0 5px #cdcdcd;

            &:hover {
                box-shadow: 0 0 10px 2px #cdcdcd;
            }
        }
        img {
            width: 60px;
            margin-bottom: 25px;
        }
    }
}