.pagination_ {
    display: flex;
    padding: 10px;

    .MuiPagination-root {
        background: #fff;
        padding: 10px;
        box-shadow: 0 0 4px 2px #cdcdcd;
        border-radius: 5px;
    }
}
.pagination {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-top: 15px;
    float: inline-end;
}