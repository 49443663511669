.photo_gallery {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 8px #cdcdcd;
    border-radius: 3px;
    min-height: calc(100vh - 230px);
    margin-bottom: 16px;
    padding: 0 !important;

    .tab_option {
        display: flex;
        gap: 20px;
        padding: 10px 20px;

        .tab_item {
            align-items: center;
            cursor: pointer;
            font-size: 20px;
            color: #cdcdcd;

            &.active,
            &:hover {
                color: #11998e;
            }

            &.active {
                cursor: default;
            }
        }
    }

    .synthetic_block {
        padding: 15px;
    }

    .card-body {
        border: none;
        background-color: #f1f1f1;

        .list_img {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .img_item {
                position: relative;

                img {
                    width: 230px;
                    height: 140px;
                    object-fit: cover;
                    border-radius: 3px;
                    box-shadow: 0 0 5px 1px #fff;
                }

                .img_item_overlay {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    border-radius: 3px;

                    svg {
                        cursor: pointer;
                        color: #ffffffe0;
                        display: none;

                        &:hover {
                            color: #ffffff;
                        }
                    }
                }

                &:hover {
                    img {
                        filter: brightness(70%);
                    }

                    .img_item_overlay {
                        svg {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.region_block {
    height: calc(100vh - 430px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 20px;

    .region_item {
        .region_header {
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 20px;
                line-height: 27px;
                margin: 0;
                color: #107f76;
            }

            .region_header_btn {
                display: flex;
            }

            .button_collapse {
                color: #cdcdcd;

                .arrow_down {
                    display: block;
                }

                .arrow_left {
                    display: none;
                }

                &.collapsed {
                    .arrow_down {
                        display: none;
                    }

                    .arrow_left {
                        display: block;
                    }

                }
            }
        }

    }

    .collapse {
        position: relative;
    }

    .card {
        border: none;
    }    
}