.map_controller_container {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: center;
    gap: 5px;
    padding: 5px;
    .tool_item {
        cursor: pointer;
    }
    svg {
        color: rgb(17, 153, 142);
        &:hover {
            color: rgb(18, 194, 179);
        }
    }
    .info-box {
        position: absolute;
        top: -100px;
        left: 45%;
        background: #f1f1f1;
        border-radius: 5px;
        padding: 10px;
        .form-check {
            margin: 5px;
        }
    }
}