.gallery_images {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000000e4;
    .slick-slider {
        width: 100%;
    }
    .gallery_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 20px;

        p {
            font-size: 22px;
            line-height: 22px;
            color: #fff;
            margin: 0;
        }

        img {
            cursor: pointer;
            width: 25px;
        }
    }

    .gallery_slider {
        padding: 20px;

        .slider_main {

            display: flex;
            justify-content: center;
            .slick-slider {
                max-width: 1200px;
            }
        }

        .slider_nav {
            background-color: hsla(0, 0%, 39.2%, .3);
            margin-top: 15px;
            max-height: 19vh;
            display: flex;
            justify-content: center;
            .slick-slider {
                max-width: 1200px;
            }
        }

        .gallery_item_title {
            position: absolute;
            
            p {
                padding: 8px 26px;
                color: #fff;
                background: rgba(0, 0, 0, .6);
            }
        }

        .pagination_gallery {
            position: relative;
            top: -25vh;
            width: 100%;
            display: flex;
            justify-content: center;
            p {
                padding: 8px 26px;
                border-radius: 5px;
                color: #fff;
                background: rgba(0, 0, 0, .6);
            }
        }
        .gallery_item {
            .gallery_img {
                width: 100%;
                max-height: 70vh;
                object-fit: cover;
            }
            .gallery_img_nav {
                padding: 10px 5px 0;
                width: 100%;
                max-width: 250px;
                max-height: 15vh;
                object-fit: cover;
                filter: brightness(80%);
            }
            @media (max-width: 767px) {
                .gallery_img {
                    max-height: 30vh;
                }
                .gallery_img_nav {
                    max-height: 10vh;
                }
            }
    
        }

        
    }
}