.dialog-preview-form {
    .MuiDialog-paperWidthSm {
        max-width: 750px;
    }

    .MuiAccordionSummary-content {
        width: 90%;
    }
}

.btn_add_form {
    cursor: pointer;
    color: #3f51b5;
}

.list_upload_file {
    display: flex;
    gap: 10px;

    .btn_close {
        cursor: pointer;
        position: absolute;
        top: -4px;
        right: 15px;
        font-size: 18px;
        &:hover {
            color: #cdcdcd;
        }
    }
}