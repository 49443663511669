.map_project_container {
    height: 787px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1019607843);
    margin-bottom: 10px;
    border-radius: 8px 8px 0 0;

    .col-12 {
        padding: 0;
    }
}